import React from 'react';
import { Link } from 'gatsby';

import './chapters.css';

const chaptersMenu = ({ chapters, current }) => {
  return (
    <ul className="chapters-menu">
      {chapters.map(({ node }) => {
        return (
          <li key={node.name}>
            {node.name === current ? (
              <span>{node.name}</span>
            ) : (
              <Link to={node.fields.slug}>{node.name}</Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const chaptersPrevNext = ({ chapters, current }) => {
  const index = chapters.findIndex(({ node }) => node.name === current);
  const prev = index > 0 && chapters[index - 1];
  const next = index + 1 < chapters.length && chapters[index + 1];

  return (
    <ul className="chapters-prev-next">
      {!!prev && (
        <li className="prev">
          <Link to={prev.node.fields.slug}>←</Link>
        </li>
      )}
      {!!next && (
        <li className="next">
          <Link to={next.node.fields.slug}>→</Link>
        </li>
      )}
    </ul>
  );
};

export default ({ type, ...props }) => {
  switch (type) {
    case 'menu':
      return chaptersMenu(props);
    case 'prev-next':
      return chaptersPrevNext(props);
    default:
      return null;
  }
};
