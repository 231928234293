import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Chapters from '../components/chapters';
import TextWithNote from '../components/text-with-note';
import Header from '../components/header';
import Layout from '../components/layout';
import Container from '../components/container';

import './chapter.css';

class ChapterTemplate extends Component {
  state = { chaptersMenuVisible: false };

  toggleChaptersMenu = () => {
    this.setState(({ chaptersMenuVisible }) => ({
      chaptersMenuVisible: !chaptersMenuVisible
    }));
  };

  render() {
    const {
      location,
      data: { chapter: doc, chapters }
    } = this.props;
    const { chaptersMenuVisible } = this.state;

    return (
      <Layout location={location}>
        <Container className="chapter-container">
          <div className="content">
            <article>
              <Header>
                <h1>
                  {doc.book}. <em>{doc.name}</em>
                  <button
                    className="chapters-menu--toggle"
                    onClick={this.toggleChaptersMenu}
                  >
                    {chaptersMenuVisible ? '▲' : '▼'}
                  </button>
                </h1>
                {!!chaptersMenuVisible && (
                  <Chapters
                    chapters={chapters.edges}
                    current={doc.name}
                    type="menu"
                  />
                )}
                <Helmet title={`${doc.book} — ${doc.name}`} />
              </Header>
              <TextWithNote {...doc} />
              <Chapters
                chapters={chapters.edges}
                current={doc.name}
                type="prev-next"
              />
            </article>
          </div>
        </Container>
      </Layout>
    );
  }
}
export default ChapterTemplate;

export const query = graphql`
  query($slug: String!, $book: String!) {
    chapter(fields: { slug: { eq: $slug } }) {
      html
      book
      name
      notes {
        id
        html
      }
    }
    chapters: allChapter(filter: { book: { eq: $book } }) {
      edges {
        node {
          name
          fields {
            slug
          }
        }
      }
    }
  }
`;
